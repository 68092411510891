import { useUser } from '../contexts/UserContext';
import { redirect } from 'react-router-dom';

// Hook pour la protection des routes accessibles à tous les utilisateurs authentifiés
export const useProtectedLoader = () => {
  const { isLoggedOn } = useUser();

  function protectedLoader() {
    // Si le statut de connexion n'est pas déterminé, ne rien faire
    if (isLoggedOn === undefined) {
      return null;
    }
    // Rediriger vers la page de connexion si l'utilisateur n'est pas connecté
    if (!isLoggedOn) {
      return redirect(`/login`);
    }

    return null;
  }
  return protectedLoader;
};


// Hook pour la protection des routes réservées aux administrateurs
export const useAdminProtectedLoader = () => {
  const { isLoggedOn, type } = useUser();

  function adminProtectedLoader() {
    // Rediriger vers la page de connexion si l'utilisateur n'est pas connecté
    if (!isLoggedOn) {
      return redirect("/login");
    }
    // Rediriger vers une page d'erreur si l'utilisateur n'est pas administrateur
    if (type !== "admin") {
      return redirect("/unauthorized");
    }

    return null;
  }

  return adminProtectedLoader;
};
