import React, { Suspense, lazy } from 'react';

import {
  RouterProvider,
  createBrowserRouter
} from "react-router-dom";

// Layouts
import { LayoutAuth } from "layouts/Auth";
import { LayoutAdmin } from "layouts/Admin";

// Hooks
import { useLoginAction, useLoginLoader } from "hooks/useLogin";
import { useProtectedLoader } from "hooks/useAuth";

import PageError from "views/PageError";
import Login from "views/Login";

const Loading = lazy(() => import("views/Loading"));

const Dashboard = lazy(() => import("views/Index"));
const UserProfile = lazy(() => import("views/Profile"));
const Ordinateurs = lazy(() => import("views/examples/Ordinateurs"));
const EcransInteractifs = lazy(() => import("views/examples/EcransInteractifs"));
const CablesHdmi = lazy(() => import("views/examples/CablesHdmi"));
const Lieux = lazy(() => import("views/Lieux"));

const Formateurs = lazy(() => import("views/examples/Formateurs"));
const GroupesFormation = lazy(() => import("views/examples/GroupesFormations"));

const Emprunt = lazy(() => import("views/examples/Emprunts"));
const EmpruntEcranInteractif = lazy(() => import("views/examples/EmpruntEcranInteractifs"));
const EmpruntCableHdmi = lazy(() => import("views/examples/EmpruntCablesHdmi"));

const Deplacement = lazy(() => import("views/examples/Deplacement"));

const HistoriqueEmpruntEcranInteractif = lazy(() => import("views/examples/HistoriqueEmpruntEcranInteractif"));
const HistoriqueEmpruntOrdinateur = lazy(() => import("views/examples/HistoriqueEmpruntOrdinateur"));
const HistoriqueEmpruntCableHdmi = lazy(() => import("views/examples/HistoriqueEmpruntCableHdmi"));
const HistoriqueDeplacementOrdinateur = lazy(() => import("views/examples/HistoriqueDeplacementOrdinateur"));

const Router = () => {
  const loginLoader = useLoginLoader();
  const loginAction = useLoginAction();
  const protectedLoader = useProtectedLoader();

  const router = createBrowserRouter([
    {
      path: '/',
      errorElement: (
        <LayoutAdmin>
          <PageError />
        </LayoutAdmin>
      ),
      children: [
        {
          index: true,
          path: '/',
          loader: protectedLoader,
          element: (
            <LayoutAdmin>
              <Suspense fallback={<Loading />}>
                <Dashboard />
              </Suspense>
            </LayoutAdmin>
          )
        },
        {
          path: 'login',
          loader: loginLoader,
          action: loginAction,
          element: (
            <LayoutAuth>
              <Login />
            </LayoutAuth>
          )
        },
        {
          path: 'ordinateurs',
          loader: protectedLoader,
          element: (
            <LayoutAdmin>
              <Suspense fallback={<Loading />}>
                <Ordinateurs />
              </Suspense>
            </LayoutAdmin>
          )
        },
        {
          path: 'ecrans-interactifs',
          loader: protectedLoader,
          element: (
            <LayoutAdmin>
              <Suspense fallback={<Loading />}>
                <EcransInteractifs />
              </Suspense>
            </LayoutAdmin>

          )
        },
        {
          path: 'cables-hdmi',
          loader: protectedLoader,
          element: (
            <LayoutAdmin>
              <Suspense fallback={<Loading />}>
                <CablesHdmi />
              </Suspense>
            </LayoutAdmin>

          )
        },
        {
          path: 'formateurs',
          loader: protectedLoader,
          element: (
            <LayoutAdmin>
              <Suspense fallback={<Loading />}>
                <Formateurs />
              </Suspense>
            </LayoutAdmin>

          )
        },
        {
          path: 'groupes-formation',
          loader: protectedLoader,
          element: (
            <LayoutAdmin>
              <Suspense fallback={<Loading />}>
                <GroupesFormation />
              </Suspense>
            </LayoutAdmin>
          )
        },
        {
          path: 'emprunts/ordinateurs',
          loader: protectedLoader,
          element: (
            <LayoutAdmin>
              <Suspense fallback={<Loading />}>
                <Emprunt />
              </Suspense>
            </LayoutAdmin>
          )
        },
        {
          path: 'emprunts/ecrans-interactifs',
          loader: protectedLoader,
          element: (
            <LayoutAdmin>
              <Suspense fallback={<Loading />}>
                <EmpruntEcranInteractif />
              </Suspense>
            </LayoutAdmin>
          )
        },
        {
          path: 'emprunts/cables-hdmi',
          loader: protectedLoader,
          element: (
            <LayoutAdmin>
              <Suspense fallback={<Loading />}>
                <EmpruntCableHdmi />
              </Suspense>
            </LayoutAdmin>
          )
        },
        {
          path: 'deplacements',
          loader: protectedLoader,
          element: (
            <LayoutAdmin>
              <Suspense fallback={<Loading />}>
                <Deplacement />
              </Suspense>
            </LayoutAdmin>
          )
        },
        {
          path: 'historique/deplacement/ordinateur',
          loader: protectedLoader,
          element: (
            <LayoutAdmin>
              <Suspense fallback={<Loading />}>
                <HistoriqueDeplacementOrdinateur />
              </Suspense>
            </LayoutAdmin>
          )
        },

        {
          path: 'lieux',
          loader: protectedLoader,
          element: (
            <LayoutAdmin>
              <Suspense fallback={<Loading />}>
                <Lieux />
              </Suspense>
            </LayoutAdmin>
          )
        },
        {
          path: 'historique/emprunt/cables-hdmi',
          loader: protectedLoader,
          element: (
            <LayoutAdmin>
              <Suspense fallback={<Loading />}>
                <HistoriqueEmpruntCableHdmi />
              </Suspense>
            </LayoutAdmin>
          )
        },
        {
          path: 'historique/emprunt/ordinateur',
          loader: protectedLoader,
          element: (
            <LayoutAdmin>
              <Suspense fallback={<Loading />}>
                <HistoriqueEmpruntOrdinateur />
              </Suspense>
            </LayoutAdmin>
          )
        },
        {
          path: 'historique/emprunt/ecran-interactif',
          loader: protectedLoader,
          element: (
            <LayoutAdmin>
              <Suspense fallback={<Loading />}>
                <HistoriqueEmpruntEcranInteractif />
              </Suspense>
            </LayoutAdmin>
          )
        },
        {
          path: 'user-profile',
          loader: protectedLoader,
          element: (
            <LayoutAdmin>
              <Suspense fallback={<Loading />}>
                <UserProfile />
              </Suspense>
            </LayoutAdmin>
          )
        },
        {
          path: 'user-profile/setting',
          loader: protectedLoader,
          element: (
            <LayoutAdmin>
              <Suspense fallback={<Loading />}>
                <p>Reglage de mon profil</p>
              </Suspense>
            </LayoutAdmin>
          )
        },
      ]
    }
  ]);


  return <RouterProvider router={router} />
}

export default Router;