import React from "react";
import ReactDOM from "react-dom/client";

// React-query
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';

import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";

import App from './App';
import { UserProvider } from './contexts/UserContext';
import { LocationProvider } from './contexts/LocationContext';

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <QueryClientProvider client={queryClient}>
    <UserProvider>
      <LocationProvider>
        <App />
      </LocationProvider>
    </UserProvider>

    <ReactQueryDevtools initialIsOpen={false} />
  </QueryClientProvider >
  // </React.StrictMode>
);