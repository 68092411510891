import axios from 'axios';

// const baseURL = 'https://emprunt-pc-ifr-api.vercel.app';
// const baseURL = 'http://localhost:3001';
const baseURL = 'https://api-emprunt.ifr-reunion.re';


const instance = axios.create({
    baseURL,
    withCredentials: true,
    headers: {
        // Type de contenu pour les requêtes JSON
        'Content-Type': 'application/json' 
    }
});

// Pour la deconnexion automatique en cas de manque de cookie ou expiré
instance.interceptors.response.use(
    (response) => response,
    error => {
        const status = error?.response?.status || null;
        const responseURL = new URL(error?.request?.responseURL || "");

        // On vérifie si le chemin contient '/api/me', indépendamment du domaine
        const isApiMeEndpoint = responseURL.pathname === "/api/me";

        if (status === 401 && !isApiMeEndpoint) {
            window.location.href = '/login';
        }
        return Promise.reject(error);
    }
);

const API_URL = '/api/';

export { instance, API_URL };