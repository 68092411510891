import React from "react";

import {
    Container,
} from "reactstrap";

// Import Header
import Header from "components/Headers/Header";


const Profile = () => {

    return (
        <>
            <Header />

            {/* Page content */}
            <Container className="text-center bg-gradient-info pb-9" fluid>
                <h1>Page introuvable !</h1>
            </Container>
        </>
    );
};

export default Profile;
