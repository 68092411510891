import React, { useEffect } from 'react';
import Router from './Router';
import { useUser } from 'contexts/UserContext';


function App() {
    // Utilisation du hook useUser pour obtenir l'état de l'authentification
    const { authenticate } = useUser();

    // Utilisation d'un effet pour s'authentifier lors du chargement initial
    useEffect(() => {
        authenticate()
    }, [authenticate]);

    return (
        <>
            <Router />
        </>
    );
}

export default App;