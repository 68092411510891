import React, { useState } from "react";
import { NavLink as NavLinkRRD, Link } from "react-router-dom";

// nodejs library to set properties for components
import { PropTypes } from "prop-types";

// reactstrap components
import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col
} from "reactstrap";

import ButtonChangeLocation from "components/Input/ButtonChangeLocation";

// CONTEXT
import { useUser } from 'contexts/UserContext';


const Sidebar = (props) => {
  const { logOut, userProfile } = useUser();

  const [collapseOpen, setCollapseOpen] = useState();
  const [isSubMenuOpen, setSubMenuOpen] = useState(false);


  const toggleSubMenu = () => {
    setSubMenuOpen(!isSubMenuOpen);
  };

  // toggles collapse between opened and closed (true/false)
  const toggleCollapse = () => {
    setCollapseOpen((data) => !data);
  };
  // closes the collapse
  const closeCollapse = () => {
    setCollapseOpen(false);
  };


  const { logo } = props;
  let navbarBrandProps;
  if (logo && logo.innerLink) {
    navbarBrandProps = {
      to: logo.innerLink,
      tag: Link
    };
  } else if (logo && logo.outterLink) {
    navbarBrandProps = {
      href: logo.outterLink,
      target: "_blank"
    };
  }

  return (
    <Navbar
      className="navbar-vertical fixed-left navbar-light bg-white"
      expand="md"
      id="sidenav-main"
    >
      <Container fluid>
        {/* Toggler */}
        <button
          className="navbar-toggler"
          type="button"
          onClick={toggleCollapse}
        >
          <span className="navbar-toggler-icon" />
        </button>

        {/* Brand */}
        {logo ? (
          <NavbarBrand className="pt-0" {...navbarBrandProps}>
            <img
              alt={logo.imgAlt}
              className="navbar-brand-img"
              src={logo.imgSrc}
            />
          </NavbarBrand>
        ) : null}

        {/* Collapse */}
        <Collapse navbar isOpen={collapseOpen}>
          {/* Collapse header */}
          <div className="navbar-collapse-header d-md-none">
            <Row>
              {logo ? (
                <Col className="collapse-brand" xs="6">
                  {logo.innerLink ? (
                    <Link to={logo.innerLink}>
                      <img alt={logo.imgAlt} src={logo.imgSrc} />
                    </Link>
                  ) : (
                    <a href={logo.outterLink}>
                      <img alt={logo.imgAlt} src={logo.imgSrc} />
                    </a>
                  )}
                </Col>
              ) : null}
              <Col className="collapse-close" xs="6">
                <button
                  className="navbar-toggler"
                  type="button"
                  onClick={toggleCollapse}
                >
                  <span />
                  <span />
                </button>
              </Col>
            </Row>
          </div>

          {/* Bouton */}
          {userProfile && (userProfile.type === "admin" || userProfile.type === "equipe_pedagogique") && (
            <ButtonChangeLocation />
          )}



          {/* Divider */}
          <hr className="my-3" />

          {/* Navigation */}
          <Nav className="mb-md-3" navbar>
            <NavItem>
              <NavLink
                to="/"
                tag={NavLinkRRD}
                onClick={closeCollapse}
              >
                <i className="ni ni ni-chart-pie-35 text-primary" />
                Tableau de bord
              </NavLink>
            </NavItem>

            {userProfile && (userProfile.type === "admin" || userProfile.type === "equipe_pedagogique") ? (
              <>
                <NavItem>
                  <NavLink
                    data-toggle="collapse"
                    role="button"
                    aria-expanded={isSubMenuOpen ? "true" : "false"}
                    aria-controls="sub-menu"
                    className={isSubMenuOpen ? "" : "collapsed"}
                    onClick={toggleSubMenu}
                  >
                    <i className="ni ni ni-calendar-grid-58  text-primary" />
                    <span className="nav-link-text">Emprunts</span>
                  </NavLink>
                  <Collapse id="sub-menu" isOpen={isSubMenuOpen}>
                    <ul className="nav nav-sm flex-column">
                      <NavItem>
                        <NavLink
                          to="/emprunts/ordinateurs/"
                          tag={NavLinkRRD}
                          onClick={closeCollapse}
                        >
                          <i className="ni ni ni-bold-right text-dark" />
                          Emprunts d'ordinateur
                        </NavLink>
                      </NavItem>

                      <NavItem>
                        <NavLink
                          to="/emprunts/ecrans-interactifs/"
                          tag={NavLinkRRD}
                          onClick={closeCollapse}
                        >
                          <i className="ni ni ni-bold-right text-dark" />
                          Emprunts d'écran interactif
                        </NavLink>
                      </NavItem>

                      <NavItem>
                        <NavLink
                          to="/emprunts/cables-hdmi/"
                          tag={NavLinkRRD}
                          onClick={closeCollapse}
                        >
                          <i className="ni ni ni-bold-right text-dark" />
                          Emprunts de câble HDMI
                        </NavLink>
                      </NavItem>
                    </ul>
                  </Collapse>
                </NavItem>

                <NavItem>
                  <NavLink
                    to="/ordinateurs/"
                    tag={NavLinkRRD}
                  >
                    <i className="ni ni ni-laptop text-primary" />
                    Ordinateurs
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    to="/ecrans-interactifs/"
                    tag={NavLinkRRD}
                  >
                    <i className="ni ni ni-tv-2 text-primary" />
                    Écrans interactifs
                  </NavLink>
                </NavItem>


                <NavItem>
                  <NavLink
                    to="/cables-hdmi/"
                    tag={NavLinkRRD}
                  >
                    <i className="ni ni ni-curved-next text-primary" />
                    Câbles HDMI
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    to="/formateurs/"
                    tag={NavLinkRRD}
                  >
                    <i className="ni ni-single-02 text-primary" />
                    Formateurs
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    to="/groupes-formation/"
                    tag={NavLinkRRD}
                  >
                    <i className="ni ni ni-ungroup text-primary" />
                    Groupe de formation
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    to="/deplacements/"
                    tag={NavLinkRRD}
                  >
                    <i className="ni ni ni-send text-primary" />
                    Déplacements
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    to="/lieux/"
                    tag={NavLinkRRD}
                  >
                    <i className="ni ni-map-big text-primary" />
                    Lieux
                  </NavLink>
                </NavItem>

                {/* <NavItem>
                  <NavLink
                    to="/historiques/"
                    tag={NavLinkRRD}
                  >
                    <i className="ni ni ni-archive-2 text-primary" />
                    Historiques
                  </NavLink>
                </NavItem> */}
              </>
            ) : (
              <>
                <NavItem>
                  <NavLink
                    to="/emprunts/ordinateurs/"
                    tag={NavLinkRRD}
                    onClick={closeCollapse}
                  >
                    <i className="ni ni ni-laptop text-primary" />
                    Emprunts d'ordinateur
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    to="/emprunts/ecrans-interactifs/"
                    tag={NavLinkRRD}
                    onClick={closeCollapse}
                  >
                    <i className="ni ni ni-tv-2 text-primary" />
                    Emprunts d'écran interactif
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    to="/emprunts/cables-hdmi/"
                    tag={NavLinkRRD}
                    onClick={closeCollapse}
                  >
                    <i className="ni ni-curved-next text-primary" />
                    Emprunts de câble HDMI
                  </NavLink>
                </NavItem>
              </>
            )}

          </Nav>
          <Nav className="mb-md-3" navbar>
            <NavItem className="active-pro active">
              <NavLink
                onClick={logOut}
              >
                <i className="ni ni-spaceship" />
                Déconnexion
              </NavLink>
            </NavItem>
          </Nav>

        </Collapse>
      </Container>
    </Navbar>
  );
};

Sidebar.defaultProps = {
  routes: [{}]
};

Sidebar.propTypes = {
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the image src of the logo
    imgSrc: PropTypes.string.isRequired,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired
  })
};

export default Sidebar;